<template>
    <v-footer color="grey lighten-3" padless>
        <v-container class="pt-6 pb-0">
            <v-row class="justify-center justify-md-space-between py-3">
                <v-col
                    class="d-flex align-center justify-center"
                    cols="12"
                    sm="auto"
                >
                    <router-link to="/" style="max-width: 100%">
                        <v-img
                            :src="require('@/assets/logo.png')"
                            alt="Career Crows"
                            class="shrink"
                            contain
                            height="25"
                            transition="scale-transition"
                            width="158"
                        />
                    </router-link>
                </v-col>

                <v-col
                    class="d-flex flex-wrap align-center justify-center"
                    cols="12"
                    sm="auto"
                >
                    <v-btn to="/policy" plain x-small> Privacy Policy </v-btn>

                    <v-btn to="/contact" plain x-small> Contact Us </v-btn>

                    <v-btn to="/policy#ca" plain x-small>
                        CA - Do Not Sell My Personal Information
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="d-flex flex-column flex-wrap pt-0 pb-6">
            <v-row>
                <v-col>
                    <p class="mb-0 py-3 text-body-2 text-center">
                        © {{ new Date().getFullYear() }} Career Crows.
                        <strong>All rights reserved</strong>.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
    data: () => ({
        //
    }),
};
</script>